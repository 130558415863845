<template>
  <div class="main-page" ref="titlePage">
    <div class="container">
      <aside class="d-md-none">
          <app-bar
              title="Daftar"
              previousLink="/"
          />
      </aside>
      <div class="row">
        <div class="col-md-5 d-flex px-0">
          <div class="form-register w-100 px-3">
            <div class="group-title mb-4">
              <span class="title-content-package">Daftar</span> <br>
              <span class="fw-normal fs-12 color-neutral-600">Silahkan lengkapi data diri di bawah ini</span>
            </div>
            <span v-if="errorMessage" class="invalid-feedback error">{{ errorMessage }}</span>
            <!-- <Form @submit="$emit('update:currentStep', currentStep+1)" v-slot="{ errors }"> -->
            <Form @submit="submit()" v-slot="{ errors }" enctype="multipart/form-data">
              <label>Nama Pengguna</label>
              <div class="input-group mb-3">
                <Field
                  type="text"
                  v-model="dataRegister.nama"
                  name="nama"
                  rules="required"
                  class="form-control"
                  :class="{ 'is-invalid': errors.nama }"
                  placeholder="Cth: para.hita1234" />
                  <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.nama }">
                  <div class="show-password" type="button" @click="dataRegister.nama = ''" :class="{'opacity-0':!dataRegister.nama}">
                    <i class="ri-close-circle-fill fs-24"></i>
                  </div>
                </span>
                <ErrorMessage name="nama" class="invalid-feedback" />
              </div>

              <label>No. KTP</label>
              <div class="input-group mb-3">
                <Field
                  type="number"
                  min='0'
                  v-model="dataRegister.noktp"
                  name="noktp"
                  rules="required|digits:16"
                  class="form-control"
                  :class="{ 'is-invalid': errors.noktp }"
                  placeholder="xxxx xxxx xxxx xxxx xxxx xxxx"
                  @change="onChangeKTPNumber($event)"
                />
                <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.noktp }">
                  <div class="show-password" type="button" @click="dataRegister.noktp = ''" :class="{'opacity-0':!dataRegister.noktp}">
                    <i class="ri-close-circle-fill fs-24"></i>
                  </div>
                </span>
                <ErrorMessage name="noktp" class="invalid-feedback" />
              </div>

              <label>Alamat Email</label>
              <div class="input-group mb-3">
                <Field
                  type="email"
                  v-model="dataRegister.email"
                  name="email"
                  rules="required"
                  class="form-control"
                  :class="{ 'is-invalid': errors.email }"
                  placeholder="Cth: nama@email.com" />
                <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.email }">
                  <div class="show-password" type="button" @click="dataRegister.email = ''" :class="{'opacity-0':!dataRegister.email}">
                    <i class="ri-close-circle-fill fs-24"></i>
                  </div>
                </span>
                <ErrorMessage name="email" class="invalid-feedback" />
              </div>

              <label>Kata Sandi</label>
              <div class="input-group mb-3">
                <Field
                  :type="type"
                  v-model="dataRegister.password"
                  name="password"
                  rules="required"
                  class="form-control"
                  :class="{ 'is-invalid': errors.password }"
                  id="password-visibility"
                  placeholder="Kata Sandi"
                />
                <span class="input-group-text bg-grayscale border-0">
                  <div class="show-password" type="button" @click="type = showPassword(type)">
                    <i class="ri-eye-fill fs-24" v-if="type === 'password'"></i>
                    <i class="ri-eye-off-fill fs-24" v-if="type === 'text'"></i>
                  </div>
                </span>
                <ErrorMessage name="password" class="invalid-feedback" />
              </div>

              <label>Tanggal Lahir</label>
              <div class="input-group mb-3 row-flex justify-content-between gap-1">
                <div class="col-3">
                  <Field
                    type="number"
                    v-model="date.tgl"
                    name="tgllahir"
                    class="form-control"
                    min="1"
                    placeholder="Tanggal"
                  />
                </div>
                <div class="col-5">
                  <select
                    class="form-select select-month color-neutral-900"
                    id="inputGroupSelect01"
                    v-model="date.bln"
                    name="bulan">
                      <option selected disabled value="">Pilih Bulan</option>
                      <option value="01">Januari</option>
                      <option value="02">Februari</option>
                      <option value="03">Maret</option>
                      <option value="04">April</option>
                      <option value="05">Mei</option>
                      <option value="06">Juni</option>
                      <option value="07">Juli</option>
                      <option value="08">Agustus</option>
                      <option value="09">September</option>
                      <option value="10">Oktober</option>
                      <option value="11">November</option>
                      <option value="12">Desember</option>
                  </select>
                </div>
                <div class="col-3">
                  <Field
                    type="number"
                    v-model="date.thn"
                    name="tahun"
                    max="4"
                    class="form-control"
                    placeholder="Tahun"
                  />
                </div>
                <ErrorMessage name="tgllahir" class="invalid-feedback" />
              </div>

              <label>No. Telp</label>
              <div class="input-group mb-3">
                <VueTelInput
                  v-model="dataRegister.no_hp"
                  name="no_hp"
                  rules="required"
                  mode="international"
                  class="form-control"
                  :class="{ 'is-invalid': errors.no_hp }"
                  :inputOptions="{
                    'placeholder': vueTelOption.placeholder
                  }"
                  :validCharactersOnly="true"
                  @input="handleInputHP"
                  @change="handleWa"
                />
                <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.no_hp }">
                  <div class="show-password" type="button" @click="dataRegister.no_hp = ''" :class="{'opacity-0':!dataRegister.no_hp}">
                    <i class="ri-close-circle-fill fs-24"></i>
                  </div>
                </span>
                <ErrorMessage name="no_hp" class="invalid-feedback" />
              </div>

              <div v-if="!sameAsWa">
                <label>WhatsApp</label>
                <div class="input-group mb-3">
                  <VueTelInput
                    v-model="dataRegister.nowa"
                    name="nowa"
                    rules="required"
                    mode="international"
                    class="form-control"
                    :class="{ 'is-invalid': errors.nowa }"
                    :inputOptions="{
                      'placeholder': vueTelOption.placeholder
                    }"
                    :validCharactersOnly="true"
                    @input="handleInputWA"
                    @change="handleWa"
                  />
                  <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.nowa }">
                    <div class="show-password" type="button" @click="dataRegister.nowa = ''" :class="{'opacity-0':!dataRegister.nowa}">
                      <i class="ri-close-circle-fill fs-24"></i>
                    </div>
                  </span>
                  <ErrorMessage name="nowa" class="invalid-feedback" />
                </div>
              </div>

              <div class="input-group mb-2">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="telpSame"
                    v-model="sameAsWa"
                    @change="handleWa"
                  >
                  <label class="form-label pt-1 ms-2 mb-0 fw-400 fs-12 lh-22 color-neutral-700" for="telpSame">No. Telp sama dengan WhatsApp</label>
                </div>
              </div>

              <label class="mb-2">Jenis Kelamin</label>
              <div class="d-flex justify-content-between mb-4">
                <div class="bd-highlight w-50">
                  <div class="form-check pe-2">
                    <div
                      @click="dataRegister.jenkelamin = 'L'"
                      class="btn btn-gender d-flex justify-content-between align-items-center rounded-pill"
                      :class="{ active: (dataRegister.jenkelamin === 'L') }"
                    >
                      <p class="fst-normal fw-600 fs-12 lh-16 mb-0">Laki-laki</p>
                      <div class="box-icon bg-white rounded-circle d-flex align-items-center justify-content-center">
                          <i class="ri-women-line icon-gender color-neutral-700"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bd-highlight w-50">
                  <div class="form-check ps-2 d-flex justify-content-end">
                    <div
                      @click="dataRegister.jenkelamin = 'P'"
                      class="btn btn-gender d-flex justify-content-between align-items-center rounded-pill"
                      :class="{ active: (dataRegister.jenkelamin === 'P') }"
                    >
                      <p class="fst-normal fw-600 fs-12 lh-16 mb-0">Perempuan</p>
                      <div class="box-icon bg-white rounded-circle d-flex align-items-center justify-content-center">
                          <i class="ri-women-line icon-gender color-neutral-700"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-group">
                <div class="form-check d-flex">
                  <div class="wrapper">
                    <input
                      type="checkbox"
                      class="form-check-input me-2"
                      id="policyCheck"
                      v-model="privacyPolice"
                    >
                  </div>
                  <label class="form-label pt-1 fw-400 fs-12 lh-22 color-neutral-700 mb-0" for="policyCheck">
                    Data diatas adalah milik saya sendiri. saya juga menyetujui <span class="fw-700 fs-12 lh-22 color-secondary-500">Syarat dan Ketentuan</span> serta <span class="fw-700 fs-12 lh-22 color-secondary-500">Kebijakan Privasi</span> Hai Parahita.
                  </label>
                </div>
              </div>

              <button v-if="loading.register" type="button" class="btn btn-primary w-100 py-1 p-md-2 mt-4 mt-md-0" disabled>
                <p class="mb-0 align-items-center d-flex justify-content-center gap-2">
                  Loading <span class="spinner-border" role="status"></span>
                </p>
              </button>
              <button v-else type="submit" class="btn btn-primary w-100 py-2 p-md-3 mt-4 mt-md-0">
                  Daftar
              </button>
              <div class="d-flex justify-content-center align-items-center my-3">
                  <div class="bd-highlight">
                    <p class="fw-normal fs-14 lh-24">Sudah punya akun?</p>
                  </div>
                  <div class="bd-highlight ps-1">
                    <router-link to="/login">
                        <p class="fw-bold fs-14 lh-24">Masuk</p>
                    </router-link>
                  </div>
              </div>
          </Form>
          </div>
        </div>
        <div class="d-none d-md-block col-md-7">
            <div class="frame-image vh-md-100 d-flex align-items-center justify-content-center">
                <div>
                    <img src="@/assets/illustration.png">
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick, onMounted, ref, reactive } from 'vue'
import { title } from '@/core/page'
import AppBar from '@/components/AppBar.vue'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { dataRegister, showPassword, loading, register } from '@/core/auth'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import { useRouter } from 'vue-router'

export default {
  components: {
    AppBar,
    Field,
    Form,
    ErrorMessage,
    VueTelInput
  },
  setup () {
    const router = useRouter()
    const type = ref('password')
    const privacyPolice = ref(false)
    const sameAsWa = ref(false)
    const errorMessage = ref('')
    const date = reactive({
      tgl: null,
      bln: '',
      thn: null
    })
    const titlePage = ref(null)

    const handleWa = () => {
      if (sameAsWa.value) {
        dataRegister.nowa = dataRegister.no_hp
      }
    }

    const handleInputHP = (phone, phoneObject) => {
      if (phoneObject?.formatted) {
        dataRegister.no_hp = phoneObject.formatted
      }
    }

    const handleInputWA = (phone, phoneObject) => {
      if (phoneObject?.formatted) {
        dataRegister.nowa = phoneObject.formatted
      }
    }

    const onChangeKTPNumber = (event) => {
      dataRegister.noktp = event.target.value
    }

    const submit = async () => {
      try {
        if (!privacyPolice.value) {
          errorMessage.value = 'Silahkan centang persetujuan kebijakan privasi'
          titlePage.value.scrollIntoView({ behavior: 'smooth' })
          return
        }

        // cek tanggal
        if (!date.tgl) {
          errorMessage.value = 'Silahkan mengisi tanggal lahir'
          titlePage.value.scrollIntoView({ behavior: 'smooth' })
          return
        }
        if (!date.bln) {
          errorMessage.value = 'Silahkan mengisi bulan lahir'
          titlePage.value.scrollIntoView({ behavior: 'smooth' })
          return
        }
        if (!date.thn) {
          errorMessage.value = 'Silahkan mengisi tahun lahir'
          titlePage.value.scrollIntoView({ behavior: 'smooth' })
          return
        }

        // cek nomor
        if (!dataRegister.no_hp) {
          errorMessage.value = 'Silahkan mengisi nomor telepon'
          titlePage.value.scrollIntoView({ behavior: 'smooth' })
          return
        }
        if (!dataRegister.nowa) {
          errorMessage.value = 'Silahkan mengisi nomor whatsapp'
          titlePage.value.scrollIntoView({ behavior: 'smooth' })
          return
        }

        const fd = new FormData()
        dataRegister.tgllahir = date.thn + '-' + date.bln + '-' + date.tgl

        // Append object ke formData
        Object.keys(dataRegister).forEach(key => fd.append(key, dataRegister[key]))
        // Baru send
        const result = await register(fd)
        if (!result.status) {
          errorMessage.value = result.message
          titlePage.value.scrollIntoView({ behavior: 'smooth' })
          return
        }

        // Jika sukses, kirim OTP
        // const sendingOtp = await sendOtp({
        //   email: dataRegister.email
        // })
        // if (!sendingOtp.status) {
        //   errorMessage.value = sendingOtp.message
        //   return
        // }

        router.replace({ name: 'OTP', params: { email: dataRegister.email } })
      } catch (error) {
        errorMessage.value = error.response.data.message
        titlePage.value.scrollIntoView({ behavior: 'smooth' })
      }
    }

    onMounted(() => {
      nextTick(() => {
        title.value = 'Daftar'
      })
    })

    return {
      dataRegister,
      showPassword,
      type,
      date,
      sameAsWa,
      handleWa,
      loading,
      submit,
      privacyPolice,
      errorMessage,
      handleInputHP,
      handleInputWA,
      titlePage,
      onChangeKTPNumber
    }
  },
  data () {
    return {
      vueTelOption: {
        placeholder: '8xxx xxxx xxxx'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-register {
  // padding-top: 2rem;

  @media (min-width: 520px) {
    padding-top: 25%;
  }
}

.form-control::placeholder {
  color: #CBD5E0;
}

.form-control, .input-group-text {
  background: none;
  border-radius: 0;
  box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
  padding: 8px 4px 8px 0px;
  font-size: 18px;
}

.form-control.is-invalid, .input-group-text.is-invalid {
  box-shadow: inset 0px -1px 0px #E21F39;
}

.vue-tel-input {
  border: none;
}

select.select-month {
  height: 100%;
  font-size: 18px;
  line-height: 24px;
  border: none;
  box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;
  background-image: url("../../assets/icon/select-arrow.png");
  background-repeat: no-repeat;
  background-size: 14px auto;
}

.btn-gender {
  padding: 4px 4px 4px 12px;
  border-radius: 8px;
  background: #EFF0F7;
  color: #4E4B66;
  width: 100%;
}

.btn-gender.active {
  background: #3566FC;
  color: #FFFFFF;
}

.box-icon {
  width: 30px;
  height: 30px;
}

input[type="radio"] {
  display: none;
  position: absolute;
}

.form-check {
  padding-left: unset;
}

.form-check .form-check-input {
  margin-left: 0;
}

.input-group>.form-control.form-error {
  background: #FFDDEB;
  color: #14142B;
}

.frame-image {
  background: #007a9826;
  width: 100%;
  height: 100%;
}

// .frame-image img {
//   width: 90%;
//   height: auto;
// }

@media (min-width: 360px) and (max-width: 767px) {
  .col-md-5 {
      padding-right: 0px !important;
  }

  .form-login {
      padding-right: 1rem !important;
  }

  .col-md-7 {
      padding-left: 0px;
  }

  .frame-image {
      position: relative;
      background: #007a9826;
      width: 100%;
  }
}

.invalid-feedback.error {
  font-weight: 700;
  font-size: 14px;
}
</style>
